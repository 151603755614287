<template>
    <v-container fluid>
        <PageHeaderSlot :isLoading="$store.getters.isLoading"> </PageHeaderSlot>
        <v-row>
            <v-col cols="12">
                <v-form ref="dataForm" @submit.prevent="save">
                    <v-card class="mb-8">
                        <v-card-title tag="h1" class="mb-4">Main Banner</v-card-title>
                        <v-card-text>
                            <ImageUploader 
                                singleImage 
                                fileInputKey="upload_image_banner"
                                :imgList.sync="formData.banner_image" 
                                acceptFormat="image"
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></ImageUploader>
                        </v-card-text>
                    </v-card>

                    <v-card style="overflow: hidden;">
                        <v-card-text class="pa-0">
                            <v-tabs 
                                v-model="tab" 
                                background-color="primary" 
                                dark 
                                align-with-title 
                                show-arrows
                            >
                                <v-tab v-for="(item, index) in tabItems" :key="`tab_${index}`">{{ item }}</v-tab>
                            </v-tabs>

                            <v-tabs-items v-model="tab">
                                <v-tab-item>
                                    <v-card flat>
                                        <v-card-text class="pa-0">
                                            <v-row class="my-0 pa-5">
                                                <v-col cols="12">
                                                    <FormTextarea 
                                                        label="Introduction Content"
                                                        :fieldValue.sync="tpuFormData.introduction"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                    ></FormTextarea>
                                                </v-col>
                                                <v-col cols="12">
                                                    <ImageUploader 
                                                        singleImage 
                                                        fileInputKey="upload_tpu_image"
                                                        :imgList.sync="tpuFormData.image" 
                                                        acceptFormat="image"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                    ></ImageUploader>
                                                </v-col>
                                                <v-col cols="12" class="mt-8">
                                                    <h3>Basic Specifications</h3>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="4">
                                                    <FormInput
                                                        label="SHORE HARDNESS"
                                                        :fieldValue.sync="tpuFormData.shore_hardness"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        suffix="A"
                                                    ></FormInput>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="4">
                                                    <FormInput
                                                        label="TENSILE STRENGTH"
                                                        :fieldValue.sync="tpuFormData.tensile_strength"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        suffix="MPA"
                                                    ></FormInput>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="4">
                                                    <FormInput
                                                        label="COMPRESSIVE SET"
                                                        :fieldValue.sync="tpuFormData.compressive_set_percent"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        suffix="%"
                                                    ></FormInput>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="4">
                                                    <FormInput
                                                        label="ELONGATION AT BREAK"
                                                        :fieldValue.sync="tpuFormData.elongation_at_break"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        suffix="%"
                                                    ></FormInput>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="4">
                                                    <FormInput
                                                        label="COMPRESSIVE SET"
                                                        :fieldValue.sync="tpuFormData.compressive_set_degree"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        suffix="°c"
                                                    ></FormInput>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-10 mb-0 pa-5">
                                                <v-col cols="12">
                                                    <div class="d-flex">
                                                        <h3>Technical Specifications</h3>
                                                        <v-btn small depressed color="primary accent-4 mx-4" class="white--text" type="button"
                                                            :disabled="$store.getters.isLoading" @click="openAddTechSpecDialog('tpu')">Add</v-btn>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                            <Datatable 
                                                :tableData="tpuFormData.techTableData" 
                                                :tableHeaders="techTableHeaders" 
                                                disablePagination
                                                disableFooter 
                                                disableToolbar 
                                                disableShadow 
                                                @view-clicked="openAddTechSpecDialog('tpu', $event)"
                                                @delete-clicked="handleDeleteTechSpec('tpu', $event)"
                                            ></Datatable>
                                           
                                           
                                            <v-row class="mt-10 mb-0 pa-5">
                                                <v-col cols="12">
                                                    <div class="d-flex">
                                                        <h3>Datasheet</h3>
                                                        <v-btn small depressed color="primary accent-4 mx-4" class="white--text" type="button"
                                                            :disabled="$store.getters.isLoading" @click="openAddDatasheetDialog('tpu')">Add</v-btn>
                                                    </div>
                                                </v-col>
                                            </v-row>

                                            <Datatable 
                                                :tableData="tpuFormData.datasheetTableData" 
                                                :tableHeaders="datasheetTableHeaders" 
                                                disablePagination
                                                disableFooter 
                                                disableToolbar 
                                                disableShadow 
                                                @view-clicked="openAddDatasheetDialog('tpu', $event)"
                                                @delete-clicked="handleDeleteDatasheet('tpu', $event)"
                                            ></Datatable>


                                            <v-row class="mt-10 mb-0 pa-5">
                                                <v-col cols="12">
                                                    <h3>PDF Upload</h3>
                                                </v-col>

                                                <v-col cols="12">
                                                    <ImageUploader 
                                                        singleImage
                                                        fileInputKey="upload_tpu_pdf"
                                                        :imgList.sync="tpuFormData.pdf_file" 
                                                        acceptFormat="pdfOnly"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        uploadText="Upload PDF"
                                                    ></ImageUploader>
                                                </v-col>
                                            </v-row>
                                        
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>

                                <v-tab-item>
                                    <v-card flat>
                                        <v-card-text class="pa-0">
                                            <v-row class="my-0 pa-5">
                                                <v-col cols="12">
                                                    <FormTextarea 
                                                        label="Introduction Content"
                                                        :fieldValue.sync="nylonFormData.introduction"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                    ></FormTextarea>
                                                </v-col>
                                                <v-col cols="12">
                                                    <ImageUploader 
                                                        singleImage 
                                                        fileInputKey="upload_nylon_image"
                                                        :imgList.sync="nylonFormData.image" 
                                                        acceptFormat="image"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                    ></ImageUploader>
                                                </v-col>
                                                <v-col cols="12" class="mt-8">
                                                    <h3>Basic Specifications</h3>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="4">
                                                    <FormInput
                                                        label="TENSILE MODULUS"
                                                        :fieldValue.sync="nylonFormData.tensile_modulus"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        suffix="MPA"
                                                    ></FormInput>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="4">
                                                    <FormInput
                                                        label="TENSILE STRENGTH"
                                                        :fieldValue.sync="nylonFormData.tensile_strength"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        suffix="MPA"
                                                    ></FormInput>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="4">
                                                    <FormInput
                                                        label="IMPACT STRENGTH"
                                                        :fieldValue.sync="nylonFormData.impact_strength"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        suffix="KJ/M²"
                                                    ></FormInput>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="4">
                                                    <FormInput
                                                        label="ELONGATION AT BREAK"
                                                        :fieldValue.sync="nylonFormData.elongation_at_break"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        suffix="%"
                                                    ></FormInput>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="4">
                                                    <FormInput
                                                        label="HDT@0.45 MPA"
                                                        :fieldValue.sync="nylonFormData.hdt"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        suffix="°c"
                                                    ></FormInput>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-10 mb-0 pa-5">
                                                <v-col cols="12">
                                                    <div class="d-flex">
                                                        <h3>Technical Specifications</h3>
                                                        <v-btn small depressed color="primary accent-4 mx-4" class="white--text" type="button"
                                                            :disabled="$store.getters.isLoading" @click="openAddTechSpecDialog('nylon')">Add</v-btn>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                            <Datatable 
                                                :tableData="nylonFormData.techTableData" 
                                                :tableHeaders="techTableHeaders" 
                                                disablePagination
                                                disableFooter 
                                                disableToolbar 
                                                disableShadow 
                                                @view-clicked="openAddTechSpecDialog('nylon', $event)"
                                                @delete-clicked="handleDeleteTechSpec('nylon', $event)"
                                            ></Datatable>
                                           
                                           
                                            <v-row class="mt-10 mb-0 pa-5">
                                                <v-col cols="12">
                                                    <div class="d-flex">
                                                        <h3>Datasheet</h3>
                                                        <v-btn small depressed color="primary accent-4 mx-4" class="white--text" type="button"
                                                            :disabled="$store.getters.isLoading" @click="openAddDatasheetDialog('nylon')">Add</v-btn>
                                                    </div>
                                                </v-col>
                                            </v-row>

                                            <Datatable 
                                                :tableData="nylonFormData.datasheetTableData" 
                                                :tableHeaders="datasheetTableHeaders" 
                                                disablePagination
                                                disableFooter 
                                                disableToolbar 
                                                disableShadow 
                                                @view-clicked="openAddDatasheetDialog('nylon', $event)"
                                                @delete-clicked="handleDeleteDatasheet('nylon', $event)"
                                            ></Datatable>


                                            <v-row class="mt-10 mb-0 pa-5">
                                                <v-col cols="12">
                                                    <h3>PDF Upload</h3>
                                                </v-col>

                                                <v-col cols="12">
                                                    <ImageUploader 
                                                        singleImage
                                                        fileInputKey="upload_nylon_pdf"
                                                        :imgList.sync="nylonFormData.pdf_file" 
                                                        acceptFormat="pdfOnly"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        uploadText="Upload PDF"
                                                    ></ImageUploader>
                                                </v-col>
                                            </v-row>
                                        
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>

                                <v-tab-item>
                                    <v-card flat>
                                        <v-card-text class="pa-0">
                                            <v-row class="my-0 pa-5">
                                                <v-col cols="12">
                                                    <FormTextarea 
                                                        label="Introduction Content"
                                                        :fieldValue.sync="slsFormData.introduction"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                    ></FormTextarea>
                                                </v-col>
                                                <v-col cols="12">
                                                    <ImageUploader 
                                                        singleImage 
                                                        fileInputKey="upload_sls_image1"
                                                        :imgList.sync="slsFormData.image1" 
                                                        acceptFormat="image"
                                                        uploadText="Upload Image 1"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                    ></ImageUploader>
                                                </v-col>
                                              
                                                <v-col cols="12" class="mt-8">
                                                    <h3>Basic Specifications</h3>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="4">
                                                    <FormInput
                                                        label="TENSILE MODULUS"
                                                        :fieldValue.sync="slsFormData.tensile_modulus"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        suffix="MPA"
                                                    ></FormInput>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="4">
                                                    <FormInput
                                                        label="TENSILE STRENGTH"
                                                        :fieldValue.sync="slsFormData.tensile_strength"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        suffix="MPA"
                                                    ></FormInput>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="4">
                                                    <FormInput
                                                        label="IMPACT STRENGTH"
                                                        :fieldValue.sync="slsFormData.impact_strength"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        suffix="KJ/M²"
                                                    ></FormInput>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="4">
                                                    <FormInput
                                                        label="ELONGATION AT BREAK"
                                                        :fieldValue.sync="slsFormData.elongation_at_break"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        suffix="%"
                                                    ></FormInput>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="4">
                                                    <FormInput
                                                        label="HDT@0.45 MPA"
                                                        :fieldValue.sync="slsFormData.hdt"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        suffix="°c"
                                                    ></FormInput>
                                                </v-col>
                                                <v-col cols="12">
                                                    <ImageUploader 
                                                        singleImage 
                                                        fileInputKey="upload_sls_image2"
                                                        :imgList.sync="slsFormData.image2" 
                                                        acceptFormat="image"
                                                        uploadText="Upload Image 2"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                    ></ImageUploader>
                                                </v-col>
                                            </v-row>
                                           
                                            <v-row class="mt-10 mb-0 pa-5">
                                                <v-col cols="12">
                                                    <div class="d-flex">
                                                        <h3>Technical Specifications</h3>
                                                        <v-btn small depressed color="primary accent-4 mx-4" class="white--text" type="button"
                                                            :disabled="$store.getters.isLoading" @click="openAddTechSpecDialog('sls')">Add</v-btn>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                            <Datatable 
                                                :tableData="slsFormData.techTableData" 
                                                :tableHeaders="techTableHeaders" 
                                                disablePagination
                                                disableFooter 
                                                disableToolbar 
                                                disableShadow 
                                                @view-clicked="openAddTechSpecDialog('sls', $event)"
                                                @delete-clicked="handleDeleteTechSpec('sls', $event)"
                                            ></Datatable>
                                           
                                           
                                            <v-row class="mt-10 mb-0 pa-5">
                                                <v-col cols="12">
                                                    <div class="d-flex">
                                                        <h3>Datasheet</h3>
                                                        <v-btn small depressed color="primary accent-4 mx-4" class="white--text" type="button"
                                                            :disabled="$store.getters.isLoading" @click="openAddDatasheetDialog('sls')">Add</v-btn>
                                                    </div>
                                                </v-col>
                                            </v-row>

                                            <Datatable 
                                                :tableData="slsFormData.datasheetTableData" 
                                                :tableHeaders="datasheetTableHeaders" 
                                                disablePagination
                                                disableFooter 
                                                disableToolbar 
                                                disableShadow 
                                                @view-clicked="openAddDatasheetDialog('sls', $event)"
                                                @delete-clicked="handleDeleteDatasheet('sls', $event)"
                                            ></Datatable>


                                            <v-row class="mt-10 mb-0 pa-5">
                                                <v-col cols="12">
                                                    <h3>PDF Upload</h3>
                                                </v-col>

                                                <v-col cols="12">
                                                    <ImageUploader 
                                                        singleImage
                                                        fileInputKey="upload_sls_pdf"
                                                        :imgList.sync="slsFormData.pdf_file" 
                                                        acceptFormat="pdfOnly"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        uploadText="Upload PDF"
                                                    ></ImageUploader>
                                                </v-col>
                                            </v-row>
                                        
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>

                                <v-tab-item>
                                    <v-card flat>
                                        <v-card-text class="pa-0">
                                            <v-row class="my-0 pa-5">
                                                <v-col cols="12">
                                                    <FormTextarea 
                                                        label="Introduction Content"
                                                        :fieldValue.sync="tpuSlsFormData.introduction"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                    ></FormTextarea>
                                                </v-col>
                                                <v-col cols="12">
                                                    <ImageUploader 
                                                        singleImage 
                                                        fileInputKey="upload_tpuSls_image1"
                                                        :imgList.sync="tpuSlsFormData.image1" 
                                                        acceptFormat="image"
                                                        uploadText="Upload Image 1"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                    ></ImageUploader>
                                                </v-col>
                                              
                                                <v-col cols="12" class="mt-8">
                                                    <h3>Basic Specifications</h3>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="4">
                                                    <FormInput
                                                        label="SHORE HARDNESS"
                                                        :fieldValue.sync="tpuSlsFormData.shore_hardness"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        suffix="A"
                                                    ></FormInput>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="4">
                                                    <FormInput
                                                        label="TENSILE STRENGTH"
                                                        :fieldValue.sync="tpuSlsFormData.tensile_strength"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        suffix="MPA"
                                                    ></FormInput>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="4">
                                                    <FormInput
                                                        label="COMPRESSIVE SET"
                                                        :fieldValue.sync="tpuSlsFormData.compressive_set"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        suffix="%"
                                                    ></FormInput>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="4">
                                                    <FormInput
                                                        label="ELONGATION AT BREAK"
                                                        :fieldValue.sync="tpuSlsFormData.elongation_at_break"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        suffix="%"
                                                    ></FormInput>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="4">
                                                    <FormInput
                                                        label="HDT@0.45 MPA"
                                                        :fieldValue.sync="tpuSlsFormData.hdt"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        suffix="°c"
                                                    ></FormInput>
                                                </v-col>
                                                <v-col cols="12">
                                                    <ImageUploader 
                                                        singleImage 
                                                        fileInputKey="upload_tpuSls_image2"
                                                        :imgList.sync="tpuSlsFormData.image2" 
                                                        acceptFormat="image"
                                                        uploadText="Upload Image 2"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                    ></ImageUploader>
                                                </v-col>
                                            </v-row>
                                           
                                            <v-row class="mt-10 mb-0 pa-5">
                                                <v-col cols="12">
                                                    <div class="d-flex">
                                                        <h3>Technical Specifications</h3>
                                                        <v-btn small depressed color="primary accent-4 mx-4" class="white--text" type="button"
                                                            :disabled="$store.getters.isLoading" @click="openAddDatasheetDialog('tpuSls')">Add</v-btn>
                                                    </div>
                                                </v-col>
                                            </v-row>

                                            <Datatable 
                                                :tableData="tpuSlsFormData.datasheetTableData" 
                                                :tableHeaders="datasheetTableHeaders" 
                                                disablePagination
                                                disableFooter 
                                                disableToolbar 
                                                disableShadow 
                                                @view-clicked="openAddDatasheetDialog('tpuSls', $event)"
                                                @delete-clicked="handleDeleteDatasheet('tpuSls', $event)"
                                            ></Datatable>
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>

                                <v-tab-item>
                                    <v-card flat>
                                        <v-card-text class="pa-0">
                                            <v-row class="my-0 pa-5">
                                                <v-col cols="12">
                                                    <FormTextarea 
                                                        label="Introduction Content"
                                                        :fieldValue.sync="slaFormData.introduction"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                    ></FormTextarea>
                                                </v-col>
                                                <v-col cols="12">
                                                    <ImageUploader 
                                                        singleImage 
                                                        fileInputKey="upload_sla_image1"
                                                        :imgList.sync="slaFormData.image1" 
                                                        acceptFormat="image"
                                                        uploadText="Upload Image 1"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                    ></ImageUploader>
                                                </v-col>
                                              
                                                <v-col cols="12" class="mt-8">
                                                    <h3>Basic Specifications</h3>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="4">
                                                    <FormInput
                                                        label="SHORE HARDNESS"
                                                        :fieldValue.sync="slaFormData.shore_hardness"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        suffix="A"
                                                    ></FormInput>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="4">
                                                    <FormInput
                                                        label="TENSILE STRENGTH"
                                                        :fieldValue.sync="slaFormData.tensile_strength"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        suffix="MPA"
                                                    ></FormInput>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="4">
                                                    <FormInput
                                                        label="WATER ADSORPTION"
                                                        :fieldValue.sync="slaFormData.water_adsorption"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        suffix="%"
                                                    ></FormInput>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="4">
                                                    <FormInput
                                                        label="ELONGATION AT BREAK"
                                                        :fieldValue.sync="slaFormData.elongation_at_break"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        suffix="%"
                                                    ></FormInput>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="4">
                                                    <FormInput
                                                        label="REBOUND RESILIENCE"
                                                        :fieldValue.sync="slaFormData.rebound_resilience"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                        suffix="%"
                                                    ></FormInput>
                                                </v-col>
                                                <v-col cols="12">
                                                    <ImageUploader 
                                                        singleImage 
                                                        fileInputKey="upload_sla_image2"
                                                        :imgList.sync="slaFormData.image2" 
                                                        acceptFormat="image"
                                                        uploadText="Upload Image 2"
                                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                                    ></ImageUploader>
                                                </v-col>
                                            </v-row>
                                            
                                            <div v-for="item in slaTechTypes">
                                                <v-row class="mt-10 mb-0 pa-5">
                                                    <v-col cols="12">
                                                        <div class="d-flex">
                                                            <h3>Technical Specifications - {{ item.text }}</h3>
                                                            <v-btn small depressed color="primary accent-4 mx-4" class="white--text" type="button"
                                                                :disabled="$store.getters.isLoading" @click="openAddTechSpecDialog(item.value)">Add</v-btn>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                                <Datatable 
                                                    :tableData="getTableData(item.value)" 
                                                    :tableHeaders="slaTechTableHeaders" 
                                                    disablePagination
                                                    disableFooter 
                                                    disableToolbar 
                                                    disableShadow 
                                                    @view-clicked="openAddTechSpecDialog(item.value, $event)"
                                                    @delete-clicked="handleDeleteTechSpec(item.value, $event)"
                                                ></Datatable>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-card-text>
                    </v-card>


                    <v-btn depressed color="primary accent-4" class="white--text mt-8" type="submit"
                        :loading="$store.getters.isLoading"> Save </v-btn>
                </v-form>
            </v-col>
        </v-row>


        <FormDialog 
            ref="techSpecFormDialog" 
            title="Add Technical Specifications" 
            hideToolBar 
            :maxWidth="900"
            @submit-clicked="handleTechSpecDialogConfirm()" 
            formRef="techSpecForm"
        >
            <v-row class="ma-0">
                <v-col cols="12">
                    <FormTextarea 
                        label="Title" 
                        :fieldValue.sync="techSpecForm.title"
                        :rows="2"
                    ></FormTextarea>
                </v-col>
                <v-col cols="12" v-show="showNorm">
                    <FormInput 
                        label="Norm" 
                        :fieldValue.sync="techSpecForm.norm"
                    ></FormInput>
                </v-col>
                <v-col cols="12">
                    <FormTextarea 
                        label="Value" 
                        :fieldValue.sync="techSpecForm.content"
                        :rows="2"
                    ></FormTextarea>
                </v-col>
            </v-row>
        </FormDialog>
        <FormDialog 
            ref="datasheetFormDialog" 
            title="Add Datasheet" 
            hideToolBar 
            :maxWidth="900"
            @submit-clicked="handleDatasheetDialogConfirm()" 
            formRef="datasheetForm"
        >
            <v-row class="ma-0">
                <v-col cols="12" md="6">
                    <FormTextarea 
                        label="Parameter" 
                        :fieldValue.sync="datasheetForm.parameter"
                        :rows="2"
                    ></FormTextarea>
                </v-col>
                <v-col cols="12" md="6">
                    <FormTextarea 
                        label="Value" 
                        :fieldValue.sync="datasheetForm.value"
                        :rows="2"
                    ></FormTextarea>
                </v-col>
                <v-col cols="12" md="6">
                    <FormInput 
                        label="Unit" 
                        :fieldValue.sync="datasheetForm.unit"
                    ></FormInput>
                </v-col>
                <v-col cols="12" md="6">
                    <FormInput 
                        label="Method" 
                        :fieldValue.sync="datasheetForm.method"
                    ></FormInput>
                </v-col>
            </v-row>
        </FormDialog>
    </v-container>
</template>
  
<script>
import { mapActions } from 'vuex';
import FormInput from '@/components/form/FormInput.vue';
import FormSelect from '@/components/form/FormSelect.vue';
import ImageUploader from '@/components/ImageUploader.vue';
import FormTextarea from '@/components/form/FormTextarea.vue';
import Datatable from '@/components/Datatable.vue';
import FormDialog from '@/components/FormDialog.vue';

export default {
    name: 'PrintingMaterials',
    components: {
        FormInput,
        FormSelect,
        ImageUploader,
        FormTextarea,
        Datatable,
        FormDialog,
    },
    computed: {
        dispatchUpdateOnChange() {
            return true;
        },
    },
    data: () => ({
        tab: 0,
        rowCount: 0,
        showNorm: false,
        formData: {
            banner_image: [],
        },

        tpuFormData: {
            introduction: '',
            image: [],
            shore_hardness: '',
            tensile_strength: '',
            compressive_set_percent: '',
            elongation_at_break: '',
            compressive_set_degree: '',
            techTableData: [],
            datasheetTableData: [],
            pdf_file: [],
        },

        nylonFormData: {
            introduction: '',
            image: [],
            tensile_modulus: '',
            tensile_strength: '',
            impact_strength: '',
            elongation_at_break: '',
            hdt: '',
            techTableData: [],
            datasheetTableData: [],
            pdf_file: [],
        },

        slsFormData: {
            introduction: '',
            image1: [],
            image2: [],
            tensile_modulus: '',
            tensile_strength: '',
            impact_strength: '',
            elongation_at_break: '',
            hdt: '',
            techTableData: [],
            datasheetTableData: [],
            pdf_file: [],
        },

        tpuSlsFormData: {
            introduction: '',
            image1: [],
            image2: [],
            shore_hardness: '',
            tensile_strength: '',
            compressive_set: '',
            elongation_at_break: '',
            hdt: '',
            datasheetTableData: [],
        },

        slaFormData: {
            introduction: '',
            image1: [],
            image2: [],
            shore_hardness: '',
            tensile_strength: '',
            water_adsorption: '',
            elongation_at_break: '',
            rebound_resilience: '',
            generalTechTableData: [],
            tensileTechTableData: [],
            impactTechTableData: [],
            mechanicalTechTableData: [],
            thermalTechTableData: [],
            bioTechTableData: [],
            otherTechTableData: [],
        },

        techSpecForm: {
            title: '',
            norm: '',
            content: '',
        },
        datasheetForm: {
            parameter: '',
            value: '',
            unit: '',
            method: '',
        },


        // --- static data
        tabItems: [
            'BASF MJF Ultrasint TPU-01',
            'MJF Nylon 12 – PA12',
            'PA 12 for Selective Laser Sintering (SLS)',
            'TPU for Selective Laser Sintering',
            'Flexible & Elastomeric Resins - Stereolithography SLA'
        ],
        types: [
            { text: 'Multi Jet Fusion (MJF)', value: 'mjf' },
            { text: 'Selective Laser Sintering (SLS)', value: 'sls' },
            { text: 'Stereolithography (SLA)', value: 'sla' },
            { text: 'Fused Deposition model (FDM)', value: 'fdm' },
        ],
        techTableHeaders: [
            { text: 'Title', value: 'title', width: 300 },
            { text: 'Value', value: 'content' },
            { text: '', value: 'actionViewDelete', width: 240  },
        ],
        slaTechTableHeaders: [
            { text: 'Title', value: 'title', width: 300 },
            { text: 'Norm', value: 'norm', width: 200 },
            { text: 'Typical Values', value: 'content', width: 200 },
            { text: '', value: 'actionViewDelete', width: 240  },
        ],
        datasheetTableHeaders: [
            { text: 'Parameter', value: 'parameter', width: 300 },
            { text: 'Value', value: 'value' },
            { text: 'Unit', value: 'unit' },
            { text: 'Method', value: 'method' },
            { text: '', value: 'actionViewDelete', width: 240  },
        ],
        slaTechTypes: [
            { text: 'GENERAL PROPERTIES', value: 'sla_general' },
            { text: 'TENSILE PROPERTIES', value: 'sla_tensile' },
            { text: 'IMPACT PROPERTIES', value: 'sla_impact' },
            { text: 'MECHANICAL PROPERTIES', value: 'sla_mechanical' },
            { text: 'THERMAL PROPERTIES', value: 'sla_thermal' },
            { text: 'BIOCOMPATIBILITY', value: 'sla_bio' },
            { text: 'OTHER', value: 'sla_other' },
        ],
    }),

    methods: {
        ...mapActions(['setDialogMessage', 'setShowDialog', 'setLeaveDialogLink', 'setShowLeaveDialog']),
        async getMaterialsData() {
            try {
                const data = await this.$Fetcher.GetMaterialsData();
                if (this.$validate.DataValid(data.banner_image)) {
                    this.formData.banner_image.push(data.banner_image);
                }

                if (this.$validate.DataValid(data.tup_data)) {
                    this.$set(this.tpuFormData, 'introduction', data.tup_data.introduction);
                    this.$set(this.tpuFormData, 'shore_hardness', data.tup_data.shore_hardness);
                    this.$set(this.tpuFormData, 'tensile_strength', data.tup_data.tensile_strength);
                    this.$set(this.tpuFormData, 'compressive_set_percent', data.tup_data.compressive_set_percent);
                    this.$set(this.tpuFormData, 'elongation_at_break', data.tup_data.elongation_at_break);
                    this.$set(this.tpuFormData, 'compressive_set_degree', data.tup_data.compressive_set_degree);

                    if (this.$validate.DataValid(data.tup_data.image)) {
                        this.tpuFormData.image.push(data.tup_data.image);
                    }

                    if (this.$validate.DataValid(data.tup_data.technical_spec_list)) {
                        data.tup_data.technical_spec_list.forEach(item => {
                            this.tpuFormData.techTableData.push({
                                id: this.rowCount,
                                title: item.title,
                                content: item.value,
                            });

                            this.rowCount++;
                        })
                    }

                    if (this.$validate.DataValid(data.tup_data.datasheet_list)) {
                        data.tup_data.datasheet_list.forEach(item => {
                            this.tpuFormData.datasheetTableData.push({
                                id: this.rowCount,
                                parameter: item.parameter,
                                value: item.value,
                                unit: item.unit,
                                method: item.method,
                            });

                            this.rowCount++;
                        })
                    }

                    if (this.$validate.DataValid(data.tup_data.pdf_file)) {
                        this.tpuFormData.pdf_file.push(data.tup_data.pdf_file);
                    }
                }

                if (this.$validate.DataValid(data.nylon_data)) {
                    this.$set(this.nylonFormData, 'introduction', data.nylon_data.introduction);
                    this.$set(this.nylonFormData, 'tensile_modulus', data.nylon_data.tensile_modulus);
                    this.$set(this.nylonFormData, 'tensile_strength', data.nylon_data.tensile_strength);
                    this.$set(this.nylonFormData, 'impact_strength', data.nylon_data.impact_strength);
                    this.$set(this.nylonFormData, 'elongation_at_break', data.nylon_data.elongation_at_break);
                    this.$set(this.nylonFormData, 'hdt', data.nylon_data.hdt);

                    if (this.$validate.DataValid(data.nylon_data.image)) {
                        this.nylonFormData.image.push(data.nylon_data.image);
                    }

                    if (this.$validate.DataValid(data.nylon_data.technical_spec_list)) {
                        data.nylon_data.technical_spec_list.forEach(item => {
                            this.nylonFormData.techTableData.push({
                                id: this.rowCount,
                                title: item.title,
                                content: item.value,
                            });

                            this.rowCount++;
                        })
                    }

                    if (this.$validate.DataValid(data.nylon_data.datasheet_list)) {
                        data.nylon_data.datasheet_list.forEach(item => {
                            this.nylonFormData.datasheetTableData.push({
                                id: this.rowCount,
                                parameter: item.parameter,
                                value: item.value,
                                unit: item.unit,
                                method: item.method,
                            });

                            this.rowCount++;
                        })
                    }

                    if (this.$validate.DataValid(data.nylon_data.pdf_file)) {
                        this.nylonFormData.pdf_file.push(data.nylon_data.pdf_file);
                    }
                }

                if (this.$validate.DataValid(data.sls_data)) {
                    this.$set(this.slsFormData, 'introduction', data.sls_data.introduction);
                    this.$set(this.slsFormData, 'tensile_modulus', data.sls_data.tensile_modulus);
                    this.$set(this.slsFormData, 'tensile_strength', data.sls_data.tensile_strength);
                    this.$set(this.slsFormData, 'impact_strength', data.sls_data.impact_strength);
                    this.$set(this.slsFormData, 'elongation_at_break', data.sls_data.elongation_at_break);
                    this.$set(this.slsFormData, 'hdt', data.sls_data.hdt);

                    if (this.$validate.DataValid(data.sls_data.image1)) {
                        this.slsFormData.image1.push(data.sls_data.image1);
                    }
                    if (this.$validate.DataValid(data.sls_data.image2)) {
                        this.slsFormData.image2.push(data.sls_data.image2);
                    }

                    if (this.$validate.DataValid(data.sls_data.technical_spec_list)) {
                        data.sls_data.technical_spec_list.forEach(item => {
                            this.slsFormData.techTableData.push({
                                id: this.rowCount,
                                title: item.title,
                                content: item.value,
                            });

                            this.rowCount++;
                        })
                    }

                    if (this.$validate.DataValid(data.sls_data.datasheet_list)) {
                        data.sls_data.datasheet_list.forEach(item => {
                            this.slsFormData.datasheetTableData.push({
                                id: this.rowCount,
                                parameter: item.parameter,
                                value: item.value,
                                unit: item.unit,
                                method: item.method,
                            });

                            this.rowCount++;
                        })
                    }

                    if (this.$validate.DataValid(data.sls_data.pdf_file)) {
                        this.slsFormData.pdf_file.push(data.sls_data.pdf_file);
                    }
                }

                if (this.$validate.DataValid(data.tpu_sls_data)) {
                    this.$set(this.tpuSlsFormData, 'introduction', data.tpu_sls_data.introduction);
                    this.$set(this.tpuSlsFormData, 'shore_hardness', data.tpu_sls_data.shore_hardness);
                    this.$set(this.tpuSlsFormData, 'tensile_strength', data.tpu_sls_data.tensile_strength);
                    this.$set(this.tpuSlsFormData, 'compressive_set', data.tpu_sls_data.compressive_set);
                    this.$set(this.tpuSlsFormData, 'elongation_at_break', data.tpu_sls_data.elongation_at_break);
                    this.$set(this.tpuSlsFormData, 'hdt', data.tpu_sls_data.hdt);

                    if (this.$validate.DataValid(data.tpu_sls_data.image1)) {
                        this.tpuSlsFormData.image1.push(data.tpu_sls_data.image1);
                    }
                    if (this.$validate.DataValid(data.tpu_sls_data.image2)) {
                        this.tpuSlsFormData.image2.push(data.tpu_sls_data.image2);
                    }

                    if (this.$validate.DataValid(data.tpu_sls_data.datasheet_list)) {
                        data.tpu_sls_data.datasheet_list.forEach(item => {
                            this.tpuSlsFormData.datasheetTableData.push({
                                id: this.rowCount,
                                parameter: item.parameter,
                                value: item.value,
                                unit: item.unit,
                                method: item.method,
                            });

                            this.rowCount++;
                        })
                    }
                }

                if (this.$validate.DataValid(data.sla_data)) {
                    this.$set(this.slaFormData, 'introduction', data.sla_data.introduction);
                    this.$set(this.slaFormData, 'shore_hardness', data.sla_data.shore_hardness);
                    this.$set(this.slaFormData, 'tensile_strength', data.sla_data.tensile_strength);
                    this.$set(this.slaFormData, 'water_adsorption', data.sla_data.water_adsorption);
                    this.$set(this.slaFormData, 'elongation_at_break', data.sla_data.elongation_at_break);
                    this.$set(this.slaFormData, 'rebound_resilience', data.sla_data.rebound_resilience);

                    if (this.$validate.DataValid(data.sla_data.image1)) {
                        this.slaFormData.image1.push(data.sla_data.image1);
                    }
                    if (this.$validate.DataValid(data.sla_data.image2)) {
                        this.slaFormData.image2.push(data.sla_data.image2);
                    }

                    if (this.$validate.DataValid(data.sla_data.general_technical_spec_list)) {
                        data.sla_data.general_technical_spec_list.forEach(item => {
                            this.slaFormData.generalTechTableData.push({
                                id: this.rowCount,
                                title: item.title,
                                norm: item.norm,
                                content: item.value,
                            });

                            this.rowCount++;
                        })
                    }
                    if (this.$validate.DataValid(data.sla_data.tensile_technical_spec_list)) {
                        data.sla_data.tensile_technical_spec_list.forEach(item => {
                            this.slaFormData.tensileTechTableData.push({
                                id: this.rowCount,
                                title: item.title,
                                norm: item.norm,
                                content: item.value,
                            });

                            this.rowCount++;
                        })
                    }
                    if (this.$validate.DataValid(data.sla_data.impact_technical_spec_list)) {
                        data.sla_data.impact_technical_spec_list.forEach(item => {
                            this.slaFormData.impactTechTableData.push({
                                id: this.rowCount,
                                title: item.title,
                                norm: item.norm,
                                content: item.value,
                            });

                            this.rowCount++;
                        })
                    }
                    if (this.$validate.DataValid(data.sla_data.mechanical_technical_spec_list)) {
                        data.sla_data.mechanical_technical_spec_list.forEach(item => {
                            this.slaFormData.mechanicalTechTableData.push({
                                id: this.rowCount,
                                title: item.title,
                                norm: item.norm,
                                content: item.value,
                            });

                            this.rowCount++;
                        })
                    }
                    if (this.$validate.DataValid(data.sla_data.thermal_technical_spec_list)) {
                        data.sla_data.thermal_technical_spec_list.forEach(item => {
                            this.slaFormData.thermalTechTableData.push({
                                id: this.rowCount,
                                title: item.title,
                                norm: item.norm,
                                content: item.value,
                            });

                            this.rowCount++;
                        })
                    }
                    if (this.$validate.DataValid(data.sla_data.bio_technical_spec_list)) {
                        data.sla_data.bio_technical_spec_list.forEach(item => {
                            this.slaFormData.bioTechTableData.push({
                                id: this.rowCount,
                                title: item.title,
                                norm: item.norm,
                                content: item.value,
                            });

                            this.rowCount++;
                        })
                    }
                    if (this.$validate.DataValid(data.sla_data.other_technical_spec_list)) {
                        data.sla_data.other_technical_spec_list.forEach(item => {
                            this.slaFormData.otherTechTableData.push({
                                id: this.rowCount,
                                title: item.title,
                                norm: item.norm,
                                content: item.value,
                            });

                            this.rowCount++;
                        })
                    }
                }
            } catch (err) {
                this.$common.error(err);
            } finally {
                this.$store.dispatch('toggleLoadingPage', false);
            }
        },

        async openAddTechSpecDialog(type, id) {
            if (this.$store.getters.isLoading) {
                return;
            }

            this.$set(this.techSpecForm, 'title', '');
            this.$set(this.techSpecForm, 'content', '');
            this.$set(this.techSpecForm, 'norm', '');
            this.$refs['techSpecFormDialog'].setEditOptions({ type: type });
            await this.$refs['techSpecFormDialog'].$refs['techSpecForm'].reset();

            if (type.indexOf('sla') > -1) {
                this.showNorm = true;
            } else {
                this.showNorm = false;
            }

            const tableData = this.getTableData(type);
            if (this.$validate.DataValid(id) && tableData !== null) {
                const target = tableData.find(el => el.id === id);
                if (target) {
                    this.$refs['techSpecFormDialog'].setEditId(id);
                    this.$set(this.techSpecForm, 'title', target.title);
                    this.$set(this.techSpecForm, 'content', target.content);
                    this.$set(this.techSpecForm, 'norm', target.norm);
                }
            }

            this.$refs['techSpecFormDialog'].show();
        },
        handleTechSpecDialogConfirm() {
            const id = this.$refs['techSpecFormDialog'].getEditId();
            const options = this.$refs['techSpecFormDialog'].getEditOptions();

            if (options && options.type) {
                const tableData = this.getTableData(options.type);
                if (tableData !== null) {
                    if (this.$validate.DataValid(id)) {
                        const target = tableData.find(el => el.id === id);
                        if (target) {
                            this.$set(target, 'title', this.techSpecForm.title);
                            this.$set(target, 'content', this.techSpecForm.content);
                            this.$set(target, 'norm', this.techSpecForm.norm);
                            this.$store.dispatch('setDataIsUpdated', true);
                        }
                    } else {
                        tableData.push({
                            id: this.rowCount,
                            title: this.techSpecForm.title,
                            content: this.techSpecForm.content,
                            norm: this.techSpecForm.norm
                        });
                        this.rowCount++;
                        this.$store.dispatch('setDataIsUpdated', true);
                    }
                }
            }

            this.$refs['techSpecFormDialog'].hide();
        },
        handleDeleteTechSpec(type, id) {
            const tableData = this.getTableData(type);
            if (tableData !== null) {
                const targetPos = tableData.findIndex(el => el.id === id);
                if (targetPos > -1) {
                    tableData.splice(targetPos, 1);
                    this.$store.dispatch('setDataIsUpdated', true);
                }
            } 
        },
        getTableData(type) {
            let tableData = null;
            switch (type) {
                case 'tpu':
                    tableData = this.tpuFormData.techTableData;
                    break;
                case 'nylon':
                    tableData = this.nylonFormData.techTableData;
                    break;
                case 'sls':
                    tableData = this.slsFormData.techTableData;
                    break;
                case 'sla_general':
                    tableData = this.slaFormData.generalTechTableData;
                    break;
                case 'sla_tensile':
                    tableData = this.slaFormData.tensileTechTableData;
                    break;
                case 'sla_impact':
                    tableData = this.slaFormData.impactTechTableData;
                    break;
                case 'sla_mechanical':
                    tableData = this.slaFormData.mechanicalTechTableData;
                    break;
                case 'sla_thermal':
                    tableData = this.slaFormData.thermalTechTableData;
                    break;
                case 'sla_bio':
                    tableData = this.slaFormData.bioTechTableData;
                    break;
                case 'sla_other':
                    tableData = this.slaFormData.otherTechTableData;
                    break;
                default:
                    break;
            }

            return tableData;
        },

        openAddDatasheetDialog(type, id) {
            if (this.$store.getters.isLoading) {
                return;
            }

            this.$set(this.datasheetForm, 'parameter', '');
            this.$set(this.datasheetForm, 'value', '');
            this.$set(this.datasheetForm, 'unit', '');
            this.$set(this.datasheetForm, 'method', '');
            this.$refs['datasheetFormDialog'].setEditOptions({ type: type });
            this.$refs['datasheetFormDialog'].$refs['datasheetForm'].reset();

            const tableData = this.getDatasheetData(type);
            if (this.$validate.DataValid(id) && tableData !== null) {
                const target = tableData.find(el => el.id === id);
                if (target) {
                    this.$refs['datasheetFormDialog'].setEditId(id);
                    this.$set(this.datasheetForm, 'parameter', target.parameter);
                    this.$set(this.datasheetForm, 'value', target.value);
                    this.$set(this.datasheetForm, 'unit', target.unit);
                    this.$set(this.datasheetForm, 'method', target.method);
                }
            }

            this.$refs['datasheetFormDialog'].show();
        },
        handleDatasheetDialogConfirm() {
            const id = this.$refs['datasheetFormDialog'].getEditId();
            const options = this.$refs['datasheetFormDialog'].getEditOptions();

            if (options && options.type) {
                const tableData = this.getDatasheetData(options.type);
                if (tableData !== null) {
                    if (this.$validate.DataValid(id)) {
                        const target = tableData.find(el => el.id === id);
                        if (target) {
                            this.$set(target, 'parameter', this.datasheetForm.parameter);
                            this.$set(target, 'value', this.datasheetForm.value);
                            this.$set(target, 'unit', this.datasheetForm.unit);
                            this.$set(target, 'method', this.datasheetForm.method);
                            this.$store.dispatch('setDataIsUpdated', true);
                        }
                    } else {
                        tableData.push({
                            id: this.rowCount,
                            parameter: this.datasheetForm.parameter,
                            value: this.datasheetForm.value,
                            unit: this.datasheetForm.unit,
                            method: this.datasheetForm.method
                        });
                        this.rowCount++;
                        this.$store.dispatch('setDataIsUpdated', true);
                    }
                }
            }

            this.$refs['datasheetFormDialog'].hide();
        },
        handleDeleteDatasheet(type, id) {
            const tableData = this.getDatasheetData(type);
            if (tableData !== null) {
                const targetPos = tableData.findIndex(el => el.id === id);
                if (targetPos > -1) {
                    tableData.splice(targetPos, 1);
                    this.$store.dispatch('setDataIsUpdated', true);
                }
            } 
        },
        getDatasheetData(type) {
            let tableData = null;
            switch (type) {
                case 'tpu':
                    tableData = this.tpuFormData.datasheetTableData;
                    break;
                case 'nylon':
                    tableData = this.nylonFormData.datasheetTableData;
                    break;
                case 'sls':
                    tableData = this.slsFormData.datasheetTableData;
                    break;
                case 'tpuSls':
                    tableData = this.tpuSlsFormData.datasheetTableData;
                    break;
                default:
                    break;
            }

            return tableData;
        },


        async save() {
            if (this.$store.getters.isLoading) {
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'processing',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            this.$store.dispatch('setLoading', true);
            const valid = await this.$refs['dataForm'].validate();
            if (!valid) {
                this.$store.dispatch('setLoading', false);
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'required',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            let payload = {
                banner_image: this.$validate.DataValid(this.formData.banner_image) ? this.formData.banner_image[0] : '',
                tup_data: {
                    introduction: this.tpuFormData.introduction,
                    image: this.$validate.DataValid(this.tpuFormData.image) ? this.tpuFormData.image[0] : '',
                    shore_hardness: this.tpuFormData.shore_hardness,
                    tensile_strength: this.tpuFormData.tensile_strength,
                    compressive_set_percent: this.tpuFormData.compressive_set_percent,
                    elongation_at_break: this.tpuFormData.elongation_at_break,
                    compressive_set_degree: this.tpuFormData.compressive_set_degree,
                    technical_spec_list: this.tpuFormData.techTableData.map(item => {
                        return {
                            title: item.title,
                            value: item.content
                        }
                    }),
                    datasheet_list: this.tpuFormData.datasheetTableData.map(item => {
                        return {
                            parameter: item.parameter,
                            value: item.value,
                            unit: item.unit,
                            method: item.method,
                        }
                    }),
                    pdf_file: this.$validate.DataValid(this.tpuFormData.pdf_file) ? this.tpuFormData.pdf_file[0] : '',
                },
                nylon_data: {
                    introduction: this.nylonFormData.introduction,
                    image: this.$validate.DataValid(this.nylonFormData.image) ? this.nylonFormData.image[0] : '',
                    tensile_modulus: this.nylonFormData.tensile_modulus,
                    tensile_strength: this.nylonFormData.tensile_strength,
                    impact_strength: this.nylonFormData.impact_strength,
                    elongation_at_break: this.nylonFormData.elongation_at_break,
                    hdt: this.nylonFormData.hdt,
                    technical_spec_list: this.nylonFormData.techTableData.map(item => {
                        return {
                            title: item.title,
                            value: item.content
                        }
                    }),
                    datasheet_list: this.nylonFormData.datasheetTableData.map(item => {
                        return {
                            parameter: item.parameter,
                            value: item.value,
                            unit: item.unit,
                            method: item.method,
                        }
                    }),
                    pdf_file: this.$validate.DataValid(this.nylonFormData.pdf_file) ? this.nylonFormData.pdf_file[0] : '',
                },
                sls_data: {
                    introduction: this.slsFormData.introduction,
                    image1: this.$validate.DataValid(this.slsFormData.image1) ? this.slsFormData.image1[0] : '',
                    image2: this.$validate.DataValid(this.slsFormData.image2) ? this.slsFormData.image2[0] : '',
                    tensile_modulus: this.slsFormData.tensile_modulus,
                    tensile_strength: this.slsFormData.tensile_strength,
                    impact_strength: this.slsFormData.impact_strength,
                    elongation_at_break: this.slsFormData.elongation_at_break,
                    hdt: this.slsFormData.hdt,
                    technical_spec_list: this.slsFormData.techTableData.map(item => {
                        return {
                            title: item.title,
                            value: item.content
                        }
                    }),
                    datasheet_list: this.slsFormData.datasheetTableData.map(item => {
                        return {
                            parameter: item.parameter,
                            value: item.value,
                            unit: item.unit,
                            method: item.method,
                        }
                    }),
                    pdf_file: this.$validate.DataValid(this.slsFormData.pdf_file) ? this.slsFormData.pdf_file[0] : '',
                },
                tpu_sls_data: {
                    introduction: this.tpuSlsFormData.introduction,
                    image1: this.$validate.DataValid(this.tpuSlsFormData.image1) ? this.tpuSlsFormData.image1[0] : '',
                    image2: this.$validate.DataValid(this.tpuSlsFormData.image2) ? this.tpuSlsFormData.image2[0] : '',
                    shore_hardness: this.tpuSlsFormData.shore_hardness,
                    tensile_strength: this.tpuSlsFormData.tensile_strength,
                    compressive_set: this.tpuSlsFormData.compressive_set,
                    elongation_at_break: this.tpuSlsFormData.elongation_at_break,
                    hdt: this.tpuSlsFormData.hdt,
                    datasheet_list: this.tpuSlsFormData.datasheetTableData.map(item => {
                        return {
                            parameter: item.parameter,
                            value: item.value,
                            unit: item.unit,
                            method: item.method,
                        }
                    })
                },
                sla_data: {
                    introduction: this.slaFormData.introduction,
                    image1: this.$validate.DataValid(this.slaFormData.image1) ? this.slaFormData.image1[0] : '',
                    image2: this.$validate.DataValid(this.slaFormData.image2) ? this.slaFormData.image2[0] : '',
                    shore_hardness: this.slaFormData.shore_hardness,
                    tensile_strength: this.slaFormData.tensile_strength,
                    water_adsorption: this.slaFormData.water_adsorption,
                    elongation_at_break: this.slaFormData.elongation_at_break,
                    rebound_resilience: this.slaFormData.rebound_resilience,
                    general_technical_spec_list: this.slaFormData.generalTechTableData.map(item => {
                        return {
                            title: item.title,
                            norm: item.norm,
                            value: item.content
                        }
                    }),
                    tensile_technical_spec_list: this.slaFormData.tensileTechTableData.map(item => {
                        return {
                            title: item.title,
                            norm: item.norm,
                            value: item.content
                        }
                    }),
                    impact_technical_spec_list: this.slaFormData.impactTechTableData.map(item => {
                        return {
                            title: item.title,
                            norm: item.norm,
                            value: item.content
                        }
                    }),
                    mechanical_technical_spec_list: this.slaFormData.mechanicalTechTableData.map(item => {
                        return {
                            title: item.title,
                            norm: item.norm,
                            value: item.content
                        }
                    }),
                    thermal_technical_spec_list: this.slaFormData.thermalTechTableData.map(item => {
                        return {
                            title: item.title,
                            norm: item.norm,
                            value: item.content
                        }
                    }),
                    bio_technical_spec_list: this.slaFormData.bioTechTableData.map(item => {
                        return {
                            title: item.title,
                            norm: item.norm,
                            value: item.content
                        }
                    }),
                    other_technical_spec_list: this.slaFormData.otherTechTableData.map(item => {
                        return {
                            title: item.title,
                            norm: item.norm,
                            value: item.content
                        }
                    })
                },
            }

            try {
                await this.$Fetcher.SetMaterialsData(payload);
                this.$store.dispatch('setDataIsUpdated', false);
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'Saved Successfully',
                    type: 'success',
                    refresh: true,
                    redirect: ''
                });

            } catch (err) {
                this.setDialogMessage({
                    title: "Saved Fail",
                    message: err,
                    isError: true,
                    returnLink: null,
                });
                this.setShowDialog(true);
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
    },
    async created() {
        this.$store.dispatch('toggleLoadingPage', true);
        this.getMaterialsData();
    },

    // ------ navigation guard ------
    beforeRouteLeave(to, from, next) {
        if (this.$store.getters.isLoading) {
            this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: 'processing',
                type: 'error',
                refresh: false,
                redirect: ''
            });
            next(false);
        } else if (this.$store.getters.dataIsUpdated) {
            this.setLeaveDialogLink({ name: to.name });
            this.setShowLeaveDialog(true);
            next(false);
        } else {
            next();
        }
    },
}
</script>
  